import UtilService from "./UtilService";
import UtilServiceReact from "./UtilServiceReact";

const WindowService = () => {
    const utilServiceReact = UtilServiceReact();

    // Listen for messages from the React Native side
    window.addEventListener('message', event => {
        // Parse the event data from the message
        let messageData;

        try {
            messageData = JSON.parse(event.data);
        } catch (e) {
            return;
        }

        // Check if the message is the location response
        if (messageData.type === 'requestLocationCoordinates') {
            console.log("Received data:", messageData.data);
            localStorage.setItem('requestLocationCoordinates', JSON.stringify(messageData.data));
            // Handle the 'denied' status
            if (messageData.data.status === 'denied') {
                UtilService.setLocalStorageLocationPermission('denied');
            } else {
                // Store the received coordinates in localStorage
                UtilService.setLocalStorageLocationPermission(messageData.data);
            }
        }

        // Check if the message is the location response
        if (messageData.type === 'requestLocationPermissions') {
            console.log("Received data:", messageData.data);
            localStorage.setItem('requestLocationPermissions', JSON.stringify(messageData.data));
            // Handle the 'denied' status
            if (messageData.data.status === 'denied') {
                UtilService.setLocalStorageLocationPermission('denied');
            } else {
                // Store the received coordinates in localStorage
                UtilService.setLocalStorageLocationPermission(messageData.data);
            }
        }

        if (messageData.type === 'requestOriginURL') {
            console.log("Received data:", messageData.data);
            localStorage.setItem('requestOriginURL', JSON.stringify(messageData.data));
        }

        if (messageData.type === 'requestStartRecordingMicrophonePermissions') {
            console.log("Received data:", messageData.data);
            if (messageData.data.status === 'denied') {
                localStorage.setItem('requestStartRecordingMicrophonePermissions', 'denied');
            } else {
                // Store the received coordinates in localStorage
                localStorage.setItem('requestStartRecordingMicrophonePermissions', messageData.data);
            }
        }
    });

    const sendMessageRequestReview = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("requestReview");
        } else {
            console.log('Not inside React Native WebView');
        }
    };

    const sendMessageReloadApp = () => {
        utilServiceReact.showSuccessTimerAlert("Loading", "Checking for updates...");

        setTimeout(() => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("reloadApp");
                utilServiceReact.showSuccessTimerAlert("Success", "App reloaded. You are now running the latest version!");
            } else {
                window.location.reload();
                console.log('Not inside React Native WebView');
                utilServiceReact.showWarningTimerAlert("Notice", "Reloaded. Please ensure you're running the latest version by checking updates manually.");
            }
        }, 3000);
    };


    const sendMessageRequestImagePickerPermissions = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("requestImagePickerPermissions");
        } else {
            console.log('Not inside React Native WebView');
        }
    };

    const sendMessageRequestLocationPermissions = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("requestLocationPermissions");
        }
    };

    const sendMessageRequestLocationCoordinatesPermissions = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("requestLocationCoordinates");
        } else {
            console.log('Not inside React Native WebView');
        }
    };

    const sendMessageRequestStartRecordingMicrophonePermissions = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("requestStartRecordingMicrophonePermissions");
        } else {
            console.log('Not inside React Native WebView');
        }
    };

    const sendMessageRequestStopRecordingMicrophonePermissions = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("requestStopRecordingMicrophonePermissions");
        } else {
            console.log('Not inside React Native WebView');
        }
    };

    return {
        sendMessageRequestReview,
        sendMessageReloadApp,
        sendMessageRequestImagePickerPermissions,
        sendMessageRequestLocationPermissions,
        sendMessageRequestStartRecordingMicrophonePermissions,
        sendMessageRequestStopRecordingMicrophonePermissions,
        sendMessageRequestLocationCoordinatesPermissions
    };
}

export default WindowService;
