import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import {
    Card,
    Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UtilService from "../../../../services/UtilService";
import stylesPronto from './../../../Pronto360.module.css';
import ServiceOrderService from "../../../../Admin/ServiceOrder/ServiceOrder.service";

const Notifications = () => {
    const loggedUser: any = UtilService.getLocalUserData();
    const apiUrl = process.env.REACT_APP_NODE_API_URL;
    const [isDropdownDisabled, setDropdownDisabled] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [serviceOrders, setServiceOrders] = useState<any>([]);
    const {
        fetchServiceOrdersByProviderId,
        fetchServiceOrdersByUserIdParent,
        fetchServiceOrdersByLoggedUserId
    } = ServiceOrderService();
    const handleDropdownToggle = () => {
        if (isDropdownDisabled) {
            return;
        }
    };
    useEffect(() => {
        const pathname = window.location.pathname;
        const hasAdminSupport = pathname.includes('/admin/support');
        let loggedUser = UtilService.getLocalUserData();

        if (UtilService.isAdminOrEmployee() && !UtilService.isHasDaysRemaining() && !UtilService.isAdmins(loggedUser?.email) && !hasAdminSupport) {
            navigate(`${process.env.PUBLIC_URL}/start`);
        } else {
            const userId = loggedUser?.id || loggedUser?._id;
            if (userId) {
                const providerId = UtilService.getAccountProviderId();
                if (UtilService.isProvider()) {
                    const serviceOrdersByProviderId = async () => {
                        const fetchServiceOrders = await fetchServiceOrdersByProviderId(providerId);
                        if (fetchServiceOrders) {
                            setServiceOrders(fetchServiceOrders);
                        }
                    }
                    serviceOrdersByProviderId();
                } else if (UtilService.isEmployee()) {
                    const serviceOrdersByLoggedUserId = async () => {
                        const fetchServiceOrders = await fetchServiceOrdersByLoggedUserId(userId);
                        if (fetchServiceOrders) {
                            setServiceOrders(fetchServiceOrders);
                        }
                    }
                    serviceOrdersByLoggedUserId();
                } else {
                    const serviceOrdersByUserId = async () => {
                        const fetchServiceOrders = await fetchServiceOrdersByUserIdParent(userId);
                        if (fetchServiceOrders) {
                            setServiceOrders(fetchServiceOrders);
                        }
                    }
                    serviceOrdersByUserId();
                }
            }
        }
    }, []);

    const navigate = useNavigate();
    return (
        <div>
            <Dropdown className=" nav-item main-header-notification d-flex">
                <Dropdown.Toggle className="new nav-link" href="#" variant="">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-icon-svgs"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
                    </svg>
                    <span className=" pulse"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="menu-header-content text-start border-bottom">
                        <div className="d-flex">
                            <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                                Notifications
                            </h6>
                            <span className="badge badge-pill badge-warning ms-auto my-auto float-end" style={{ display: "none" }}>
                                Mark All Read
                            </span>
                        </div>
                        <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">
                            You have highlights
                        </p>
                    </div>
                    <Card>
                        <div className="main-content-app">
                            <div className="main-content-left main-content-left-chat overflow-hidden">
                                <div className="main-chat-list" id="ChatList">
                                    <Scrollbars style={{ height: "600px" }}>
                                        <div className="media new" onClick={() =>
                                            navigate(`${process.env.PUBLIC_URL}/service-providers/roofing-contractors/roofing-replacement-near-me`)}>
                                            <div className="main-img-user online bg-warning">
                                                <i className="far fa-check-square text-white"></i>{" "}
                                                <span>1</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="media-contact-name">
                                                    <span> #Pronto25 for Roof replacement</span> <span>Offer</span>
                                                </div>
                                                <p>
                                                    Schedule now & mention #Pronto25 for discount
                                                </p>
                                            </div>
                                        </div>
                                        {serviceOrders?.map((item: any, index: number) => (
                                            <div key={index} className={`media ${item?.viewed ? 'selected' : 'new'}`} onClick={() =>
                                                navigate(`${process.env.PUBLIC_URL}/admin/service-order`, {
                                                    state: { data: { item: item } },
                                                })}>
                                                <div className={`${stylesPronto.mediaBody}`}>
                                                    <div className="media-contact-name">
                                                        <span>{UtilService.normalizeUrlToString(item?.subCategory)}</span>{" "}
                                                        <span>{UtilService.getTimePassed(item?.updatedAt)}</span>
                                                    </div>
                                                    <p style={{ margin: 0 }}>
                                                        {item?.address}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="media selected" style={{ display: "none" }}>
                                            <div className="main-img-user online">
                                                <img
                                                    alt=""
                                                    src={require("../../../../assets/img/faces/9.jpg")}
                                                />
                                            </div>
                                            <div className="media-body">
                                                <div className="media-contact-name">
                                                    <span>Reynante Labares</span>{" "}
                                                    <span>10 hours</span>
                                                </div>
                                                <p>
                                                    Nam quam nunc, bl ndit vel aecenas et ante tincid
                                                </p>
                                            </div>
                                        </div>
                                        <div className="media" style={{ display: "none" }}>
                                            <div className="main-img-user">
                                                <img
                                                    alt=""
                                                    src={require("../../../../assets/img/faces/11.jpg")}
                                                />
                                            </div>
                                            <div className="media-body">
                                                <div className="media-contact-name">
                                                    <span>Joyce Chua</span> <span>2 days</span>
                                                </div>
                                                <p>
                                                    Ma ecenas tempus, tellus eget con dimen tum
                                                    rhoncus, sem quam
                                                </p>
                                            </div>
                                        </div>
                                    </Scrollbars>
                                </div>
                                {/* <!-- main-chat-list --> */}
                            </div>
                        </div>
                    </Card>
                    <div className="dropdown-footer">
                        <Link
                            className="btn btn-primary btn-sm btn-block"
                            to={`${process.env.PUBLIC_URL}/admin/service-order`}
                        >
                            VIEW ALL
                        </Link>
                    </div>
                </Dropdown.Menu>
            </Dropdown >
        </div >
    );
}
export default Notifications;