import UtilService from "../../services/UtilService";
import ApiTokenService from "../../services/ApiTokenService";

const apiUrl: string | undefined = process.env.REACT_APP_NODE_API_URL;

const ServiceOrderService = () => {

    const apiTokenService = ApiTokenService();

    async function fetchServiceOrderData(): Promise<any> {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order', { headers });

            return Promise.resolve(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchServiceOrdersByProviderId = async (userId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/find-by-providerid/' + userId, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchServiceOrderListByProviderIdStatusAndDatePeriod = async (providerId: string, status: string, period: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/find-by-providerid-status-date-period/' + providerId + '/' + status + '/' + period, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchServiceOrdersByUserId = async (userId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/find-by-userid/' + userId, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchServiceOrdersByLoggedUserId = async (loggedUserId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/find-by-logged-userid/' + loggedUserId, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchServiceOrdersByUserIdParent = async (userId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/find-by-userid-parent/' + userId, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchServiceOrdersByRefServiceOrderId = async (refServiceOrderId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/ref-order-id/' + refServiceOrderId, { headers });
            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchServiceOrdersTotalsByProviderIdAndStatus = async (providerId: string, status: string, datePeriod: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/service-order/service-order-totals/pid/' + providerId + '/status/' + status + '/period/' + datePeriod, { headers });
            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    return {
        fetchServiceOrderData,
        fetchServiceOrdersByProviderId,
        fetchServiceOrdersByUserId,
        fetchServiceOrdersByUserIdParent,
        fetchServiceOrdersByRefServiceOrderId,
        fetchServiceOrdersByLoggedUserId,
        fetchServiceOrdersTotalsByProviderIdAndStatus,
        fetchServiceOrderListByProviderIdStatusAndDatePeriod
    }

}
export default ServiceOrderService;