import React, { useEffect } from 'react';
import './title-header.css';
import styles from '../../Pronto360.module.css'
import { Link } from 'react-router-dom';
import UtilService from '../../../services/UtilService';

interface TitleHeaderProps {
  title: string | null | undefined;
  subtitle: string | null | undefined;
  description: string | null | undefined;
  showGoBackBtn?: boolean;
  forceTarget?: string | null | undefined;
}

const TitleSubtitleBlock: React.FC<any> = ({ title, subtitle }) => {
  return (
    <>
      {UtilService.isEmpty(subtitle) && (
        <div className="m-auto text-center m-4">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {!UtilService.isEmpty(subtitle) && (
        <div className="">
          <h6 className="mb-1 tx-12 ">{String(title)?.toUpperCase()}</h6>
          <div className="d-flex">
            <h4 className="tx-16 font-weight-semibold mb-2 main-color-pronto">
              {subtitle ? subtitle?.toUpperCase() : ''}
            </h4>
          </div>
        </div>
      )}
    </>)
}

const TitleHeader: React.FC<TitleHeaderProps> = ({ title, subtitle, description, showGoBackBtn, forceTarget }) => {

  const handleGoBackHistory = () => {
    if (forceTarget) {
      window.location.href = forceTarget;
    } else {
      window.history.back();
    }
  }

  useEffect(() => { goToTop() }, [])

  const goToTop = () => {
    UtilService.screenup();
  }

  return (
    <div className="sales-card border-bottom w-100">
      <div className="ps-3 pt-4 pe-2 pb-2">
        {showGoBackBtn ? (
          <Link
            to="#"
            onClick={handleGoBackHistory}
            className="d-flex align-items-center border-0 "
          >
            <i className="fe fe-arrow-left me-2 p-2 border-primary bg-dark text-primary align-start rounded-circle w-33 omit"></i>{" "}
            <TitleSubtitleBlock title={title} subtitle={subtitle} />
          </Link>
        ) : (
          <Link
            to="#"
            className="d-flex align-items-center border-0 "
          >
            <i className="fe fe-info me-2 p-2 border-primary bg-dark text-primary align-start rounded-circle w-33"></i>{" "}
            <TitleSubtitleBlock title={String(title)?.toUpperCase()} subtitle={subtitle?.toUpperCase()} />
          </Link>
        )}
        <div className="pb-0 mt-0 print-buttons">
          <p className={`mb-0 tx-12 text-muted ${styles.maxContent} w-100`}>
            {!UtilService.isEmpty(description) ? (
              <>
                {description}
                <i className="fa fa-caret-down mx-2 text-success"></i>
              </>
            ) : (
              <>&nbsp;</>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TitleHeader;
