import UtilService from "../../../services/UtilService";
import ApiTokenService from "../../../services/ApiTokenService";

const apiUrl: string | undefined = process.env.REACT_APP_NODE_API_URL;

const UsersService = () => {

    const apiTokenService = ApiTokenService();

    async function fetchUsersData(): Promise<any> {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/user-order', { headers });

            return Promise.resolve(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUsersByUserId = async (userId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/user-order/' + userId, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const getPeriodRecords = async (daysAgo: number) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/user-order/get-period-records/' + daysAgo, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    const getPeriodRecordsDataInfo = async () => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/user-order/get-period-records-data-info', { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    async function saveUser(dataUser: any): Promise<any> {
        const headers = UtilService.getHeadersToken();
        try {
            const response = await apiTokenService.axiosInstance.post(apiUrl + '/user-order/', dataUser, { headers });
            return Promise.resolve(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function editUser(dataUser: any): Promise<any> {
        const headers = UtilService.getHeadersToken();
        try {
            const response = await apiTokenService.axiosInstance.put(apiUrl + '/user-order/update/' + dataUser?.id,
                {
                    dataUser
                },
                { headers });
            return Promise.resolve(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function removeUser(userId: string): Promise<any> {
        const headers = UtilService.getHeadersToken();
        try {
            const response = await apiTokenService.axiosInstance.delete(apiUrl + '/user-order/' + userId,
                { headers });
            return Promise.resolve(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchEmployeesByProviderId = async (providerId: string) => {
        try {
            const headers = UtilService.getHeadersToken();
            const response = await apiTokenService.axiosInstance.get(apiUrl + '/user-order/employees/' + providerId, { headers });

            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    };

    return {
        fetchUsersData,
        fetchUsersByUserId,
        saveUser,
        editUser,
        removeUser,
        getPeriodRecords,
        getPeriodRecordsDataInfo,
        fetchEmployeesByProviderId
    }

}
export default UsersService;