const TermsOfUseContent = () => {
    return (
        <div>
            <h3>Terms of Use for Pronto Services 360 App</h3>
            <p>Last Updated: 11/09/2023</p>

            <h4>1. Acceptance of Terms</h4>
            <p>By accessing and using the Pronto Services 360 application (the "App"), you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, do not use the App.</p>

            <h4>2. Description of Service</h4>
            <p>Pronto Services 360 provides a platform for users to create service orders and engage in negotiations related to construction services ("Services"). We are not a party to any agreement or negotiation between users and do not provide construction services ourselves.</p>

            <h4>3. User Conduct</h4>
            <p>Users of the App are expected to use the Services responsibly and legally. Any agreements or negotiations are solely the responsibility of the users involved.</p>

            <h4>4. Disclaimers</h4>
            <p>Pronto Services 360 is not involved in user-to-user dealings and does not guarantee the quality, safety, or legality of the services advertised, the truth or accuracy of listings, the qualifications of users, or the ability of users to perform or pay for services.</p>

            <h4>5. Limitation of Liability</h4>
            <p>Under no circumstances shall Pronto Services 360 be liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from any aspect of your use of the App, whether the damages arise from use or misuse of the App, from the inability to use the App, or the interruption, suspension, modification, alteration, or termination of the App.</p>

            <h4>6. Indemnity</h4>
            <p>You agree to indemnify and hold Pronto Services 360, our subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney's fees, made by any third party due to or arising out of your use of the App in violation of these Terms and/or arising from a breach of these Terms and/or any breach of your representations and warranties set forth above and/or if any content that you post on the App causes Pronto Services 360 to be liable to another.</p>

            <h4>7. Modification of Terms</h4>
            <p>Pronto Services 360 reserves the right, at its sole discretion, to change, modify, add, or remove portions of these Terms at any time without prior notice. Please review these Terms periodically for changes.</p>

            <h4>8. Governing Law</h4>
            <p>These Terms shall be governed by the laws of the jurisdiction in which Pronto Services 360 is registered, without regard to its conflict of law provisions.</p>

            <h4>9. Dispute Resolution</h4>
            <p>Any disputes arising out of or related to these Terms or the App will be handled through binding arbitration in accordance with the rules of a recognized arbitration body in the jurisdiction of Pronto Services 360's registration.</p>

            <h4>10. Contact Information</h4>
            <p>For any questions or concerns regarding these Terms or the Services, please contact Pronto Services 360 at contact@prontoservices360.com</p>

        </div>
    )
}
export default TermsOfUseContent;