import React from 'react'

const Loader = () => {
    return (
        <div id="global-loader">
            <div className="text-center m-4 loader-img">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
                <p className="text-center"><small>Loading...</small></p>
            </div>
        </div>
    )
}

export default Loader;