import { Provider } from 'react-redux';
import { store, persistor } from "../redux/store/store";
import Pronto360 from './Pronto360';
import UtilServiceReact from '../services/UtilServiceReact';
import ApiTokenService from '../services/ApiTokenService';

const Pronto360Index = () => {
  const utilServiceReact = UtilServiceReact();
  const apiTokenService = ApiTokenService();
  apiTokenService.validatePasswordChanged(localStorage.getItem('passwordLastChanged'));

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`)
        .then(registration => {

          console.log('SW registered: ', registration);

          registration.addEventListener('updatefound', () => {
            window.location.href = window.location.href.split('?')[0] + '?t=' + Date.now();
          });

        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
    });

    setInterval(() => {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.update();
        });
      });
    }, 1000 * 60 * 60);

  }

  return (
    <Provider store={store}>
      <Pronto360 />
    </Provider>
  );
};

export default Pronto360Index;

