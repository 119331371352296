import React, { Fragment, useEffect, useState } from "react";
import { Navbar, Dropdown, Button, Form, Col, Row, Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../Firebase/firebase";
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '../../../redux/actions/action';
import styles from "./Header.module.css";
import Notifications from "./Notifications/Notifications";
import UserMenu from "./UserMenu/UserMenu";
import UtilService from "../../../services/UtilService";

export default function Header() {
  const [Lang, setLang] = React.useState(false);
  const [fullscreens, setFullscreen] = React.useState(true);
  const location = useSelector((state: any) => state.location);
  const loggedUser = UtilService.getLocalUserData();
  const Darkmode = () => {
    document.querySelector(".app")?.classList.toggle("dark-theme");

  };

  const getdata: any = useSelector((state: any) => state.actionReducers.carts);

  const dispatch = useDispatch();

  useEffect(() => {
  }, [])

  let navigate = useNavigate();
  const routeChange = () => {
    if (UtilService.isProvider()) {
      navigate(`${process.env.PUBLIC_URL}/admin/service-order`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/`);
    }
  }
  return (

    <React.Fragment>
      <Navbar className={`${styles.prontoHeader} sticky nav nav-item`}>
        <div className="main-container container-fluid">
          <div onClick={() => routeChange()} className="header-logo">
            <img
              src={require("../../../assets/img/brand/logo-100px.png")}
              className="mobile-logo logo-1"
              alt="logo"
            />
          </div>
          <div className="main-header-right omit">
            <div className="mb-0 navbar navbar-expand-lg navbar-nav-right navbar-dark p-0">
              <ul className="nav nav-item header-icons navbar-nav-right ms-auto">
                {!UtilService.isAdminOrEmployee() && (
                  <li className="nav-item">
                    <Link
                      to={`${process.env.PUBLIC_URL}/authentication/signup`}
                      className="nav-link"
                    >
                      <Button variant="primary" className="rounded-pill px-3 tx-9 bg-red">
                        Business signup
                      </Button>
                    </Link>
                  </li>
                )}
                <Notifications />
                <UserMenu />
              </ul>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
}

