import React from 'react';
import { Link } from "react-router-dom";

const SingleInfoDescription: React.FC<any> = ({ icon, description, textTransform }) => {

    const safeDescription = typeof description === 'string' ? description : String(description) || '';

    const formattedDescription = {
        __html: safeDescription.toLocaleUpperCase()
    };

    return (
        <div className={`header-content-full text-start w-100`}>
            <div className="ms-auto d-flex">
                <div className="me-0 d-flex">
                    <span className={`${icon} text-muted me-2 tx-17`}></span>
                    <div className="mt-0 mt-0 text-dark tx-12" style={{ whiteSpace: 'pre-line', textTransform: textTransform }} dangerouslySetInnerHTML={formattedDescription}></div>
                </div>
            </div>
        </div>
    )
}
export default SingleInfoDescription;