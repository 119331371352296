
import type { CategoriesList } from '../interfaces/CategoriesList';
import categories from './../assets/data/categories.json';
import UtilService from './UtilService';

class ProductService {
    //Iterate over categories and subcategories
    static getCategories(): CategoriesList[] {
        let categoriesResult: CategoriesList[] = [];
        categories.map((category: any) => {
            let subcategories: any[] = [];
            category.subcategories.forEach((subcategory: any, subIndex: number) => {
                const subcategoryId = subIndex + 1;
                const object = this.generateObject(
                    subcategoryId,
                    `${category.name}`,
                    `${subcategory}`,
                );
                subcategories.push(object);
            });
            categoriesResult.push({ titleCategory: category.name, subCategoriesList: subcategories })
        });
        return categoriesResult;
    }

    static generateObject(id: any, category: any, subCategory: any) {
        const awsProntoRepo = process.env.REACT_APP_AWS_PRONTO_REPO;
        return {
            id: id.toString(),
            src: `${awsProntoRepo}/categories/${UtilService.normalizeUrlString(category)}/${id}.jpg`,
            num: "(20)",
            category: category,
            name: subCategory,
            text: "Lorem ipsum dolor sit amet consectetur...",
            disc: "$3,498",
            prices: "$2,999",
            price: 2999,
            Size: "Size",
            XXL: "S",
            bg: "Color :",
            colors: "pink color",
            stock: "In stock",
            stockbg: "success",
            qnty: 3,
        };
    }

    static productlist2: any = [
        {
            id: "13",
            pic1: require("../assets/img/ecommerce/9.jpg"),
            pic2: require("../assets/img/ecommerce/09.jpg"),
            item: "Glass Flower pot",
            name: "Glass Flower pot",
            qnty: 2,
            src: require("../assets/img/ecommerce/9.jpg"),
            price: "55",
            stock: "In stock",
            stockbg: "success",
            prices: "$55",
            disc: "$59",
        },
        {
            id: "14",
            pic1: require("../assets/img/ecommerce/8.jpg"),
            pic2: require("../assets/img/ecommerce/08.jpg"),
            item: "Mens party wear t-shirt",
            name: "Mens party wear t-shirt",
            qnty: 2,
            src: require("../assets/img/ecommerce/8.jpg"),
            price: "40",
            stock: "In stock",
            stockbg: "success",
            prices: "$40",
            disc: "$59",
        },
        {
            id: '15',
            pic1: require("../assets/img/ecommerce/6.jpg"),
            pic2: require("../assets/img/ecommerce/06.jpg"),
            item: "glass with soil gift item",
            name: "glass with soil gift item",
            qnty: 2,
            src: require("../assets/img/ecommerce/6.jpg"),
            price: "95",
            stock: "In stock",
            stockbg: "success",
            prices: "$95",
            disc: "$59",
        },
        {
            id: "16",
            pic1: require("../assets/img/ecommerce/1.jpg"),
            pic2: require("../assets/img/ecommerce/01.jpg"),
            item: "women party wear dress",
            name: "women party wear dress",
            qnty: 2,
            src: require("../assets/img/ecommerce/1.jpg"),
            price: "80",
            stock: "In stock",
            stockbg: "success",
            prices: "$80",
            disc: "$59",
        },
        {
            id: "17",
            pic1: require("../assets/img/ecommerce/2.jpg"),
            pic2: require("../assets/img/ecommerce/02.jpg"),
            item: "White Ear buds",
            name: "White Ear buds",
            qnty: 2,
            src: require("../assets/img/ecommerce/2.jpg"),
            price: "35",
            stock: "In stock",
            stockbg: "success",
            prices: "$35",
            disc: "$59",
        },
        {
            id: "18",
            pic1: require("../assets/img/ecommerce/3.jpg"),
            pic2: require("../assets/img/ecommerce/03.jpg"),
            item: "simple white Chair",
            name: "simple white Chair",
            qnty: 2,
            src: require("../assets/img/ecommerce/3.jpg"),
            price: "50",
            stock: "In stock",
            stockbg: "success",
            prices: "$50",
            disc: "$59",
        },
        {
            id: "19",
            pic1: require("../assets/img/ecommerce/4.jpg"),
            pic2: require("../assets/img/ecommerce/04.jpg"),
            item: "pink teddy bear",
            name: "pink teddy bear",
            qnty: 2,
            src: require("../assets/img/ecommerce/4.jpg"),
            price: "45",
            stock: "In stock",
            stockbg: "success",
            prices: "$45",
            disc: "$59",
        },
        {
            id: "20",
            pic1: require("../assets/img/ecommerce/5.jpg"),
            pic2: require("../assets/img/ecommerce/05.jpg"),
            item: "Lence Camara",
            name: "Lence Camara",
            qnty: 2,
            src: require("../assets/img/ecommerce/5.jpg"),
            price: "55",
            stock: "In stock",
            stockbg: "success",
            prices: "$55",
            disc: "$59",
        },
        {
            id: "21",
            pic1: require("../assets/img/ecommerce/7.jpg"),
            pic2: require("../assets/img/ecommerce/07.jpg"),
            item: "smooth and soft Kids wear",
            name: "smooth and soft Kids wear",
            qnty: 2,
            src: require("../assets/img/ecommerce/7.jpg"),
            price: "70",
            stock: "In stock",
            stockbg: "success",
            prices: "$70",
            disc: "$59",
        },
        {
            id: "22",
            pic1: require("../assets/img/ecommerce/11.jpg"),
            pic2: require("../assets/img/ecommerce/10.jpg"),
            item: "Branded Alaram clock",
            name: "Branded Alaram clock",
            qnty: 2,
            src: require("../assets/img/ecommerce/11.jpg"),
            price: "80",
            stock: "In stock",
            stockbg: "success",
            prices: "$80",
            disc: "$70",
        },
        {
            id: "23",
            pic1: require("../assets/img/ecommerce/13.jpg"),
            pic2: require("../assets/img/ecommerce/12.jpg"),
            item: "Branded black headset",
            name: "Branded black headset",
            qnty: 2,
            src: require("../assets/img/ecommerce/13.jpg"),
            price: "550",
            stock: "In stock",
            stockbg: "success",
            prices: "$550",
            disc: "$239",
        },
        {
            id: "24",
            pic1: require("../assets/img/ecommerce/14.jpg"),
            pic2: require("../assets/img/ecommerce/15.jpg"),
            item: "Colorful coffee cup",
            name: "Colorful coffee cup",
            qnty: 2,
            src: require("../assets/img/ecommerce/14.jpg"),
            price: "60",
            stock: "In stock",
            stockbg: "success",
            prices: "$60",
            disc: "$49",
        },
    ];
    static Datacard: any = [
        {
            PRODUCT: require('../assets/img/ecommerce/01.jpg'),
            Title: "laborum et dolorum fuga",
            PRICE: 1999,
            Size: "Size",
            XXL: "S",
            bg: "",
            colors: "",
            Quantity: 1,
        },
        {
            PRODUCT: require('../assets/img/ecommerce/02.jpg'),
            Title: "laborum et dolorum fuga",
            PRICE: 1027,
            Quantity: 3,
        },
        {
            PRODUCT: require('../assets/img/ecommerce/03.jpg'),
            Title: "laborum et dolorum fuga",
            PRICE: "$1,027",
            Quantity: 4,
        },
        {
            PRODUCT: require('../assets/img/ecommerce/04.jpg'),
            Title: "laborum et dolorum fuga",
            PRICE: "$1,027",
            Quantity: 3,
        },

    ];

    static getProductList2() {
        return ProductService.productlist2;
    }
    static getDatacard() {
        return ProductService.Datacard;
    }
    static id: any = ""
    static getidfronShop(id: any) {
        this.id = id
    }

    static returnId() {
        return this.id
    }

}
export default ProductService; 