import React from 'react';
import { Button } from 'react-bootstrap';
import './FloatingButton.css'; // This is your custom CSS file

interface FloatingButtonProps {
    icon: string;
    callback: (triggered: boolean) => void;
}
const FloatingButton: React.FC<FloatingButtonProps> = ({ icon, callback }) => {
    return (
        <Button className="floating-btn" onClick={() => callback(true)}>
            <i className={`${icon}`}></i>
        </Button>
    );
};

export default FloatingButton;
