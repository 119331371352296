import ApiTokenService from "../../../../services/ApiTokenService";
import UtilService from "../../../../services/UtilService";

const apiUrl: string | undefined = process.env.REACT_APP_NODE_API_URL;

const LoginService = () => {
    const apiTokenService = ApiTokenService();

    const makeAuthenticatedRequest = async (url: string, data: any, headers: any) => {
        try {
            await apiTokenService.apiToken();
            const apiToken = localStorage.getItem('apiToken');
            if (apiToken) {
                const response = await apiTokenService.axiosInstance.post(url, data, { headers });
                if (response.status === 200) {
                    if (response.data.token) localStorage.setItem('accessToken', response.data.token);
                    if (response.data.user) {
                        localStorage.setItem('localUser', JSON.stringify(response.data.user));
                        const localPasswordLastChanged = localStorage.getItem('passwordLastChanged');
                        if (!UtilService.isEmpty(localPasswordLastChanged)) localStorage.setItem('passwordLastChanged', response?.data.user.passwordLastChanged);
                    }
                    return response.data;
                }
            }
        } catch (error) {
            return apiTokenService.handleApiError(error);
        }
    };

    const loginUser = async (email: string, password: string) => {
        const headers = await apiTokenService.renewApiToken();
        return makeAuthenticatedRequest(`${apiUrl}/auth/login`, { email, password }, headers);
    };

    const checkUserEmail = async (email: string) => {
        const headers = await apiTokenService.renewApiToken();
        return makeAuthenticatedRequest(`${apiUrl}/auth/check-email`, { email }, headers);
    };

    return { loginUser, checkUserEmail };
};

export default LoginService;
