import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface LocationState {
    locationData: any['locationData'];
    locationPermission: boolean;
}

const initialState: LocationState = {
    locationData: {
        building: '',
        country: '',
        countryCode: '',
        county: '',
        city: '',
        houseNumber: '',
        postcode: '',
        residential: '',
        road: '',
        state: '',
        boundingbox: [],
        displayName: '',
        latitude: '',
        longitude: '',
        suburb: ''
    },
    locationPermission: false
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocationData: (state, action: PayloadAction<any['locationData']>) => {
            state.locationData = action.payload;
        },
        setLocationPermission: (state, action: PayloadAction<boolean>) => {
            state.locationPermission = action.payload;
        }
    }
});

export const { setLocationData, setLocationPermission } = locationSlice.actions;
export default locationSlice.reducer;
