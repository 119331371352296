import { type PersistConfig, type Transform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';

import locationReducer, { type LocationState } from './../reducers/locationReducer';
import { actionReducers } from './../reducers/reducer';

const serializableMiddleware = createSerializableStateInvariantMiddleware();

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage,
    transforms: [
        {
            in: (state: any) => {
                return {
                    ...state,
                    location: {
                        locationData: state.locationData,
                        locationPermission: state.locationPermission,
                    },
                };
            },
            out: (state: LocationState) => {
                return {
                    ...state,
                    location: {
                        locationData: state.locationData,
                        locationPermission: state.locationPermission,
                    },
                };
            },
        } as Transform<any, any>,
    ],
};

const rootReducer = combineReducers({
    actionReducers,
    location: locationReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false // Disable the serializable check
        }).concat(serializableMiddleware),
});

const persistor = persistStore(store);

export { store, persistor };
